<template>
  <div class="container">
    <div class="columns is-centered">
      <div class="column is-6">
        <b-message type="is-danger" has-icon>
          <b>{{ $ml.get('pages_failed_deposit_head') }}</b><br>
          {{ $ml.get('pages_failed_deposit_text') }}
        </b-message>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentFail",
  metaInfo() {
    return {
      title: this.$ml.get('titles_pages_failed_deposit')
    }
  }
}
</script>

<style scoped>

</style>